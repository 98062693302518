
html * {
  font-family: 'Open Sans','Helvetica Neue', Helvetica, Arial,'sans-serif';
  line-height: 1.1em;
}

.question-box {
  
}

.question-title {
  margin-bottom: 10px;
}

.options-radiogroup {

}

.option-radiobutton {

}

.question-subtitle {
  margin-left: 20px;
}
